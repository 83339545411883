<template>
    <div class="center main">
        <input type="checkbox" id="displayFinishedJobs" v-model="display_finished_jobs" @change="fetchJobs()" />
        <label class="displayFinishedJobs" for="displayFinishedJobs">Afficher les jobs finis</label>
        <input type="text" v-model="offset" id="offset" placeholder="0" @change="fetchJobs()"/>
        <label class="displayFinishedJobs" for="offset">Offset</label>
        <h3 v-if="fetching">Loading...</h3>
        <div v-else class="jobs center">
            <h1 v-if="jobs.length == 0">Aucun job à effectuer actuellement</h1>
    
    
            <!-- HEADER -->
            <div class="job_header">
                <div class="job_status">
                    <p>Status</p>
                </div>
                <div class="job_priority">
                    <p>Priority</p>
                </div>
                <div class="job_name">
                    <p style="margin-left:20px">Vidéo - Client</p>
                </div>
                <div class="job_server">
                    <p>Server</p>
                </div>
                <div class="job_created">
                    <p>Created</p>
                </div>
                <div class="job_done_at">
                    <p>Done</p>
                </div>
                <div class="job_log">
                </div>
                <div class="job_trash">
                </div>
                <div class="job_treeDot">
                </div>
            </div>
    
            <div v-for="(job, index) in filteredJobs" :key="job._id" class="job" :class="jobClassObject(job)" draggable="true" @dragstart="startDrag($event, index)" @drop="onDrop($event, index)" @dragover.prevent @dragenter.prevent>
                <div class="job_status" v-if="job.job_status == 0">
                    <p>Initialisation</p>
                </div>
                
                <div class="job_status" v-if="job.job_status == 1">
                    <p>En attente</p>
                </div>

                <div class="job_status" v-if="job.job_status == 2">
                    <p v-if="job.job_started_at != undefined">{{ formatDate(job.job_started_at) }}</p>
                    <p v-else>Waiting for ASK</p>
                </div>

                <div class="job_status" v-if="job.job_status == 3">
                    <p v-if="job.job_started_at != undefined">{{ formatDate(job.job_started_at) }}</p>
                    <p v-else>En cours</p>
                </div>

                <div class="job_status" v-if="job.job_status == 4">
                    <p v-if="job.job_started_at && job.video.delivered_at">{{jobDuration(job.job_started_at, job.video.delivered_at)}}</p>
                    <p v-else>Terminé</p>
                </div>

                <div class="job_status" v-if="job.job_status == 5">
                    <p>Erreur</p>
                </div>
    
                <div class="job_priority">
                    <p>{{ job.job_priority }} </p>
                </div>
                <div class="job_name">
                    <p>
                        {{ job.video.vdo_name }} - {{ job.user.usr_firstname }} {{ job.user.usr_lastname }} {{ job.user.usr_company }}
                    </p>
                </div>
                <div class="job_server">
                    {{ job.job_server }}
                </div>
                <div class="job_created">
                    {{ formatDate( job.video.created_at ) }}
                </div>
                <div class="job_done_at" v-if="job.job_status == 5 && job.job_message != null">
                    {{ job.job_message.split('\n')[0] }}
                </div>
                <div class="job_done_at" v-else-if="job.video.delivered_at">
                    {{ formatDate(job.video.delivered_at) }}
                </div>
                <div class="job_done_at" v-else>
                    <p>-</p>
                </div>
                <div class="job_log">
                    <img v-if="job.job_logs != undefined && job.job_logs != ''" src="../../../../assets/log.png" @click="getLogs(job)" />
                    <img v-else src="../../../../assets/log.png" class="disableJobsLogs" @click="getLogs(job)" />
                </div>
                <div class="job_trash">
                    <img src="../../../../assets/trash.png" @click="remove(job._id)" />
                </div>
                <div class="job_treeDot">
                    <DotsVdoMenu :lines="['Reset Job', 'Copy ID']" @clicked="jobTreeDotClicked($event, job._id, job.video._id)"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//NPM
import axios from "axios";

//Components
import DotsVdoMenu from "../../../../components/Object/ContextualMenu/ContextualMenu.vue"

export default {
    name: "Admin.job",
    data() {
        return {
            fetching : false,
            jobs: [],
            display_finished_jobs: false,
            offset : 0
        };
    },
    components : {DotsVdoMenu},
    mounted() {
        this.fetchJobs()
        setTimeout(() => {
            this.fetchJobs()
        }, 300000);
    },
    computed: {
        filteredJobs() {
            return this.jobs.filter(job => {
                if (this.display_finished_jobs == false && job.job_status == 4) {
                    return false
                } else {
                    return true
                }
            })
        }
    },

    methods: {
        fetchJobs() {
            this.fetching = true;
            axios.get("/jobs", {
                params : {
                    limit : 50,
                    offset : this.offset,
                    ...(()=>{
                        if (this.display_finished_jobs == true){
                            return {show_finsihed : true}
                        } else {
                            return {}
                        }
                    })()
                }
            }).then((res) => {
                this.fetching = false;
                console.log(res.data.length + " jobs fecthed")
                this.jobs = res.data.map(job => {
                    if (job.video == undefined) {
                        job.video = {
                            vdo_name: `(VIDEO INTROUVABLE) - Projet : ${job.project?.project_name}`,
                            vdo_state: {
                                state_ordered: null
                            }
                        }
                        job.user = {
                            usr_firstname: "USER INTROUVABLE"
                        }
                    }
                    return job
                })
            });
        },
        jobClassObject(job) {
            if (job.job_status == 1) {
                return {
                    job_pending: true,
                }
            } else if (job.job_status == 2) {
                return {
                    job_waiting_for_ask: true
                }
            } else if (job.job_status == 3) {
                return {
                    job_processing: true
                }
            } else if (job.job_status == 4) {
                return {
                    job_done: true
                }
            } else if (job.job_status == 5) {
                return {
                    job_error: true
                }
            }
        },
        formatDate(date) {
            date = new Date(date);
            return (
                date.toLocaleDateString("fr-FR") +
                " " +
                date.getHours() +
                ":" +
                (String(date.getMinutes()).length < 2 ? "0" + date.getMinutes() : date.getMinutes())
            );
        },
        startDrag: (evt, index) => {
            evt.dataTransfer.dropEffect = "move";
            evt.dataTransfer.effectAllowed = "move";
            evt.dataTransfer.setData("index", index);
        },
        onDrop(evt, targetIndex) {
            const srcIndex = evt.dataTransfer.getData("index");
            let elem = this.jobs.filter((s, index) => index == srcIndex)[0];
            this.jobs = this.jobs.filter((s, index) => index != srcIndex);
            this.jobs.splice(targetIndex, 0, elem);
            axios.put("/jobs/" + elem._id, {
                    "job_priority" : targetIndex
                })
                .then(() => {
                    this.fetchJobs()
                })
        },

        remove(id) {
            axios
                .delete("/jobs/" + id)
                .then(() => {
                    this.jobs = this.jobs.filter((j) => j._id != id);
                    this.Toaster.success(this.$t('Toaster.success.delete'))  
                })
                .catch(() => {
                    this.Toaster.error(this.$t('Toaster.error.delete'))
                });
        },
        getLogs(job){
            if (job.job_logs != undefined && job.job_logs != "undefined"){
                let jobCopy = job;
                delete jobCopy.job_logs;
                var element = document.createElement('a');
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(`${job.job_logs}\n\n --------- \n\n${JSON.stringify(jobCopy, null, 3)}`));
                element.setAttribute('download', `logs_${job._id}.log`);
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            }
        },
        jobDuration(start, end){
            const ms = Math.abs(new Date(end) - new Date(start))
            let seconds = ms / 1000;
            const hours = parseInt( seconds / 3600 );
            seconds = seconds % 3600;
            const minutes = parseInt( seconds / 60 );
            seconds = seconds % 60;
            return ( hours+"h "+minutes+"m "+seconds.toFixed(0)+"s");
        },
        jobTreeDotClicked(choiceObj, job_id, video_id){
            var choice = choiceObj.line
            switch (choice){
                case 'Reset Job' :
                    axios.put("/jobs/" + job_id, {
                        job_status : 1
                    })
                    .then(() => {
                        this.fetchJobs()
                    })
                    break;
                    
                case 'Copy ID':
                    navigator.clipboard.writeText(job_id).then(() => {
                        this.Toaster.info("ID Copied to clipboard");
                    });
                    break;
            }
        }
    },
};
</script>

<style src="@/views/Admin/subViews/style.css" scoped>

</style>

<style src='./style.css' scoped>

</style>